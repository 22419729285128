








































import { IOmiRequest } from "@/types";

import { computed, defineComponent } from "@vue/composition-api";
import { PropType } from "vue";

export default defineComponent({
  props: {
    omiRequest: {
      type: Object as PropType<IOmiRequest>
    }
  },
  setup(props, { emit }) {
    const logo = computed(() => require("@/assets/img/tng_logo.png"));

    const omi = computed(() => JSON.stringify(props.omiRequest));

    function handleRefreshQrCode() {
      emit("onRefreshQrCode");
    }
    return { logo, omi, handleRefreshQrCode };
  }
});
